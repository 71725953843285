/**
 * @fileoverview
 * File contains middleware relating to the router. This includes
 *
 * - Sentry
 * - Document title updates
 */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import { getProdApi } from 'App/consts';
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import ReactGA from 'react-ga4';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { preloadErrorStore } from 'router/interceptor-store';
import { router } from 'router/router';
import { getPageTitle } from 'utilities/methods/getPageTitle';

/**********************************************************************************************************
 * SENTRY START
 **********************************************************************************************************/
/**
 * Initializes Sentry with reference to the tanstack router for tracing.
 */
export const initializeSentry = () => {
    try {
        if (import.meta.env.MODE === 'production' && import.meta.env.VITE_COMPANY === 'ventra') {
            Sentry.init({
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.MODE,
                release: import.meta.env.VITE_GIT_SHA,
                integrations: [
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: true,
                        unmask: ['.sentry-un-mask', '[data-sentry-un-mask]'],
                    }),
                    Sentry.browserTracingIntegration(),
                    // Sentry.tanstackRouterBrowserTracingIntegration(router),
                ],
                tracePropagationTargets: [getProdApi(), /^\/api\//],
                ignoreErrors: ['ResizeObserver loop limit exceeded'],
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 0.01,
                tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE ? Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE) : 0.05,
            });
        }
    } catch (error) {
        console.error('Sentry initialisation failed.');
    }
};

/**********************************************************************************************************
 * DOCUMENT TITLE START
 **********************************************************************************************************/
/**
 * Runs whenever a route is loaded.
 *
 * Currently, This particular function handles updating the document title, but can be extended in the future.
 */
export const initializeRouteChangeHandler = () => {
    router.subscribe('onLoad', ({ pathChanged, toLocation }) => {
        if (pathChanged) {
            const { pathname } = toLocation;

            // update page title
            const newPageTitle = getPageTitle(pathname);
            if (newPageTitle) {
                document.title = newPageTitle;
            }
        }
    });
};

/**********************************************************************************************************
 * LAZY LOADING HANDLER
 **********************************************************************************************************/
/**
 * In production, if a file fails to lazy load, vite will throw an error on the window. This is caught here to ensure the listener is added before
 * react mounts, handling logic is handled within the ErrorBoundary so that we can handle it within react to display to the user.
 */
export const initializeLazyLoadHandler = () => {
    window.addEventListener('vite:preloadError', (event) => {
        // prevent app crashing
        event.preventDefault();

        // the payloads message tells us what file failed to load
        if ('payload' in event && event.payload instanceof Error) {
            const storageKey = `vite_preload_error:${event.payload.message}`;

            if (!sessionStorage.getItem(storageKey)) {
                preloadErrorStore.setState(() => 'reload' as const);
                // save local storage state for this particular error so we can prevent infinite reloading
                sessionStorage.setItem(storageKey, '1');
                window.location.reload();
            } else {
                // We've already reloaded on this error before, likely not our problem anymore, so
                // we need to display a custom message to the user (CCP-2929); This will be handled by the ErrorBoundary
                preloadErrorStore.setState(() => 'extension' as const);
            }
        }
    });
};

function hasNativeCssHasSupport() {
    try {
        if (!('CSS' in self) || !('supports' in self.CSS) || !self.CSS.supports('selector(:has(div))')) {
            return false;
        }
    } catch (_) {
        return false;
    }

    return true;
}

export const initializePolyfillHandler = async () => {
    /***** Container Query Polyfill *****/
    // Support Test
    const supportsContainerQueries = 'container' in document.documentElement.style;

    // Conditional Import
    if (!supportsContainerQueries) {
        import('container-query-polyfill' as any);
    }

    /***** :has() Polyfill *****/
    const isNativeCssHasSupported = hasNativeCssHasSupport();

    if (!isNativeCssHasSupported) {
        const { default: cssHasPseudo } = await import('css-has-pseudo/browser' as any);

        cssHasPseudo(document);
    }
};

export const initializeReactGA = () => {
    const googleAnalyticsIds = {
        sandbox: 'G-XHEEHTRRTW',
        production: 'G-VTB9C86524',
    };

    ReactGA.initialize(import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? googleAnalyticsIds.sandbox : googleAnalyticsIds.production);
};
